<template>
    <div class="pushconfig-index" v-loading="loading">
        <div class="container-header">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="medium"
                        icon="el-icon-search"
                        @click="onSearch"
                        >查询</el-button
                    >
                </div>
                <div class="action-right">
                    <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        size="medium"
                        @click="onAdd"
                        >新增</el-button
                    >
                </div>
            </div>
        </div>

        <el-table
            class="list-table"
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            :stripe="true"
            :border="false"
            v-loading="tableLoading"
        >
            <el-table-column label="数据库类型" prop="DbType">
                <template slot-scope="scope">
                    {{ getDbTypeName(scope.row.DbType) }}
                </template>
            </el-table-column>
            <el-table-column label="数据库地址" prop="DbIp"></el-table-column>
            <el-table-column label="数据库名称" prop="DbName"></el-table-column>
            <el-table-column label="数据库账号" prop="DbUser"></el-table-column>
            <el-table-column label="状态" prop="EnabledMark">
                <template slot-scope="scope">
                    <el-tag
                        v-if="!scope.row.EnabledMark"
                        effect="dark"
                        size="small"
                        >启用</el-tag
                    >
                    <el-tag
                        v-if="scope.row.EnabledMark"
                        type="danger"
                        effect="dark"
                        size="small"
                        >禁用</el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column label="时间" prop="CreateTime" width="170">
                <template slot-scope="scope">
                    {{ scope.row.CreateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="280">
                <template slot-scope="scope">
                    <router-link
                        :to="'/api/pushconfig/device?detailid=' + scope.row.Id"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-cpu"
                            size="small"
                            >绑定设备</el-button
                        >
                    </router-link>
                    <el-button
                        style="margin-left: 10px"
                        type="success"
                        icon="el-icon-edit"
                        size="mini"
                        @click="onEdit(scope.row)"
                        >修改</el-button
                    >
                    <el-button
                        type="warning"
                        icon="el-icon-error"
                        size="mini"
                        style="margin-left: 10px"
                        @click="onListDel(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            class="list-pagination"
            :page-size="page.pagesize"
            hide-on-single-page
            :total="page.total"
            :current-page.sync="page.pageindex"
            @current-change="onPageChange"
        >
        </el-pagination>

        <el-dialog
            :title="formtype == 'add' ? '添加推送数据库' : '修改推送数据库'"
            :visible.sync="dialogFormVisible"
            width="750px"
            custom-class="geology-dialog"
            :modal-append-to-body="true"
            :destroy-on-close="true"
            :before-close="onFormClose"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <el-form
                ref="form"
                class="geology-form"
                :rules="formRules"
                :model="form"
                label-width="120px"
                style="padding-top: 20px"
            >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="数据库类型" prop="DbType">
                            <el-select
                                v-model="form.DbType"
                                size="small"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in dbTypeData"
                                    :key="item.ItemValue"
                                    :label="item.ItemName"
                                    :value="item.ItemValue * 1"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="数据库地址" prop="DbIp">
                            <el-input
                                v-model="form.DbIp"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="端口" prop="Port">
                            <el-input
                                v-model="form.Port"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="数据库名称" prop="DbName">
                            <el-input
                                v-model="form.DbName"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="数据库账号" prop="DbUser">
                            <el-input
                                v-model="form.DbUser"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="数据库密码" prop="DbPwd">
                            <el-input
                                v-model="form.DbPwd"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="是否启用" prop="EnabledMark">
                            <el-switch
                                v-model="form.EnabledMark"
                                :active-value="false"
                                :inactive-value="true"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onSubmit"
                                >立即保存</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                style="margin-left: 10px"
                                @click="dialogFormVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { getPushList, delPush, addPush, editPush } from "@/api/api";
export default {
    data() {
        return {
            loading: false,
            dbTypeData: [],
            listData: [],
            tableLoading: false,
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },
            dialogFormVisible: false,
            formtype: "add",
            form: {
                DbIp: "",
                Port: "",
                DbUser: "",
                DbPwd: "",
                DbName: "",
                EnabledMark: true,
                DbType: "",
                Id: "",
            },
            formRules: {
                DbType: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择数据库类型",
                    },
                ],
                DbIp: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入数据库地址",
                    },
                ],
                Port: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入数据库端口",
                    },
                ],
                DbName: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入数据库名称",
                    },
                ],
                DbUser: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入数据库用户名",
                    },
                ],
                DbPwd: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入数据库密码",
                    },
                ],
            },
        };
    },
    components: {},
    methods: {
        getDbTypeName(dbtype) {
            var arr = this.dbTypeData.filter((element) => {
                if (element.ItemValue == dbtype) {
                    return element;
                }
            });
            if (arr.length > 0) {
                return arr[0].ItemName;
            }
            return "";
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            getPushList(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            ).then((resdata) => {
                this.tableLoading = false;
                if (resdata.code == 0) {
                    this.listData = resdata.data.data;
                    this.page.total = resdata.data.count * 1;
                } else {
                    this.$message({
                        type: "error",
                        message: resdata.msg,
                    });
                }
            });
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delPush(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => {});
        },
        onAdd() {
            this.form = {
                DbIp: "",
                Port: "",
                DbUser: "",
                DbPwd: "",
                DbName: "",
                EnabledMark: true,
                DbType: "",
                Id: "",
            };
            this.formtype = "add";
            this.dialogFormVisible = true;
        },
        onEdit(item) {
            this.form = JSON.parse(JSON.stringify(item));
            this.formtype = "edit";
            this.dialogFormVisible = true;
        },
        onFormClose() {
            this.getListData();
            this.formHeadIcon = [];
            this.dialogFormVisible = false;
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.formtype != "edit") {
                        addPush(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.onFormClose();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    } else {
                        editPush(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.onFormClose();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                } else {
                    return false;
                }
            });
        },
    },
    created() {
        this.loading = true;
        this.$store.dispatch("dictionary/getDbType").then((data) => {
            this.dbTypeData = data;
            this.loading = false;
        });
        this.getListData();
    },
};
</script>